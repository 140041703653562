

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* ====Start_admin_dashboard_card_css==== */
/* .invoice_download {
  background-color: green !important;
  color: #fff !important;
  font-size: 11px !important;
}
.invoice_download:hover {
  background-color: #016201;
} */
.custom-checkbox {
  width: 24px;
  height: 24px;
}

.custome_input{
  width: 100%;
    margin: 0px;
    background: transparent;
    color: #fff;
    padding: 0.6rem;
    border: 1px solid #fff;
    border-radius: 4px;
}
.custome_input::placeholder{
  color: #fff;
}
.custome_input:focus{
  /* outline: 0px !important; */
}
.custome_input:focus-visible{
  /* border: none; */
  outline: 0px !important;
}
/* input:-internal-autofill-selected{
  background-color: light-dark(rgb(30, 100, 219), rgba(70, 90, 126, 0.4)) !important;
  color: fieldtext !important;
} */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* ====START-MANAGE_ACCOUNT-CSS===== */
.accnt_select:focus {
  box-shadow: 0px 0px 0px 0px #000;
  border-color: #ccc;
}
.accnt_label {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  color: #000;
  margin-bottom: 0px;
} 

.account_box {
  /* background: #e5e5e5;
  padding: 50px 30px !important; */
  padding: 20px 30px !important;
  box-shadow: 0px 0px 75px -51px #000;
  -webkit-backdrop-filter: blur(17px);
  backdrop-filter: blur(17px);
  background: #fffc;
  /* border: 1px solid #f5751d; */
  border-radius: 10px;
}
.accnt_select {
  border: 1px solid #f5751d;
}
ul.accnt_totale_box li span {
  padding-right: 6rem;
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
/* ====END-MANAGE_ACCOUNT-CSS===== */


.custom-checkbox-label {
  display: flex;
  align-items: center;
}
.gst_select{
  font-size: 12px;
    width: 60px;
    padding: 0px 4px;
    margin-left: 9px;
}

.custom-checkbox-label input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin-right: 10px; /* Adjust spacing between checkbox and label */
}
.filter_block_btn{
  /* background-color: red !important; */
  padding: 10px !important;
    font-size: 15px !important;
    border-radius: 5px !important;
    color: rgb(255, 255, 255) !important;
    margin-right: 15px !important;
}
.dashboard_card_row {
  position: relative;
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  background: antiquewhite;
  padding: 15px;
  border-radius: 10px;
  overflow: hidden;
}
.d_card_left h2 {
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
  color: white !important;
}
.d_card_left h3 {
  font-size: 18px;
  font-weight: 500;
  text-transform: capitalize;
  color: white !important;
  padding: 10px 0; 
}
.d_card_left h4 {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  color: white !important;
}
.d_icon {
  font-size: 50px;
  color: white;
}

.dashboard_card_row.d_card_one {
  background-image: linear-gradient(-20deg, #0e397f 0%, #0e397fc9 100%) !important;
}
.dashboard_card_row.d_card_two {
  background-image: linear-gradient(-20deg, #1f77b4 0%, #2a9dedcf 100%) !important;
}
.dashboard_card_row.d_card_three {
  background-image: linear-gradient(-20deg, #ff7f0e 0%, #ff7f0e9e 100%) !important;
}
.dashboard_card_row.d_card_four {
  background-image: linear-gradient(-20deg, #13c74c 0%, #19c55b9e 100%) !important;
}

.dashboard_card_row::after {
  position: absolute;
  content: "";
  height: 120px;
  width: 120px;
  transform: rotate(40deg);
  top: -35px;
  right: -35px;
  border-radius: 1rem;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#00ffffff',GradientType=1 );
}
/* ======list===== */
.dshbrd_list_row ul {
  list-style: none;
  padding: 0;
}
.dshbrd_list_row ul li {
  border: 1px solid #1b4384;
  padding: 5px 6px;
  border-radius: 10px;
  margin: 5px 0;
}
.dshbrd_list_row ul li h4 {
  font-size: 14px;
  margin: 0;
  color: #1c4484;
  font-weight: 600;
  text-transform: capitalize;
}
.dshbrd_list_row ul li div span {
  font-size: 14px;
  font-weight: 600;
  color: gray;
}
h4.dshbrd_list_title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  background-color: #ccc;
  padding: 5px;
  border-radius: 7px;
}


@media (max-width: 575.98px) { 
  .dashboard_card_row::after {
    position: absolute;
    content: "";
    height: 70px;
    width: 70px;
    transform: rotate(40deg);
    top: 0px;
    right: 15px;
    border-radius: 1rem;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#00ffffff',GradientType=1 );
  }
 }

/* ====admin_dashboard_card_css_End==== */

/* =====Start-billing_list==== */
.table_remove_btn {
  font-size: 12px;
  padding: 2px 5px;
}
.table_add_btn{
  font-size: 12px;
  padding: 6px 5px;
  font-weight: 600;
}
.total_price_box {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 100%;
}

/* =====End-billing_list==== */

/* ======Session-expire-page====== */
.session_section {
  background: #0c367a;
}
.expire_session_container{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  max-height: 100%;
}
.expire_session_row {
  background: white;
  padding: 5rem 3rem;
  border-radius: 10px;
  box-shadow: 0px 4px 69px -18px #000000ba;
}
.expire_image img {
  width: 50%;
}
.expire_title {
  font-size: 30px !important;
  color: #0c367a;
  font-weight: bold !important;
  text-transform: capitalize;
  padding: 10px 0;
}
.expire_subtitle {
  font-size: 17px;
  color: #0c367a;
  font-weight: normal;
  /* text-transform: capitalize; */
}
.expire_button {
  background: #0c367a;
  /* border: 1px solid #000; */
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  margin-top: 15px;
}
.expire_session_row {
  text-align: center; 
}

  /* ======Session-expire-page-End====== */
/* .table_slct_drop.MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 29px 5px 15px !important;
  font-size: 16px;
} */
.list-pdf-file {
  width: 300px;
  height: 300px;
}
.gallery_image_box.MuiBox-root.css-0 {
  text-align: center;
}
.gallery_image_box iframe {
  height: 250px;
  width: 100%;
}
.profile_tabs{
  padding-right: 0;
    color: #092b5f;
    background-color: inherit !important;
    border-radius: 0;
    padding: 10px 0 10px !important;
    font-weight: 600;
}
.list-pdf-file img {
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 6px -2px #000;
}

.css-1x5jdmq {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  background: none;
  height: 2.4375em !important;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  width: 100%;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
  padding: 0.5px 14px 0px !important;
}

.billing_minute {
  background-color: #0c367a;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.billing_minute h6 {
  margin: 0;
  padding: 10px;
  color: white;
  font-weight: 600;
  font-size: 15px;
}
/* .MuiDataGrid-virtualScrollerContent.css-0 {
  margin-bottom: 12px !important;
}
.MuiDataGrid-virtualScroller.css-1pzb349 {
  padding-bottom: 12% !important;
} */
.MuiDataGrid-columnHeaderTitle.css-mh3zap {
  font-weight: 600 !important;
  font-size: 13px !important;
}
.mrgn_lft{
  margin-left: 0 !important;
}
.billing_minute_mobile{
  display: none;
}
/* .css-1kwdphh-MuiDataGrid-virtualScrollerContent {
  margin-bottom: 17px !important;
} */
.n-ppost-name {

  top: 100%;
  left: -18em;
  margin-top: 10px;
  width: 400px;
  height: auto;
  opacity: 0;
  -webkit-transform: translate3d(0, -15px, 0);
          transform: translate3d(0, -15px, 0);
  -webkit-transition: all 150ms linear;
  -o-transition: all 150ms linear;
  transition: all 150ms linear;
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.4;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
    z-index: 999999;
}
  
.n-ppost:hover + .n-ppost-name {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}




.css-10jhk8h {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #0c367a !important;
}
.sample_file a {
  border-bottom: 1px solid #000;
}
.dark-mode-color{
  color:#000 !important;
}

/* dialog modal card boredr radius */
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
border-radius: 10px !important;
width: 500px !important;
}
/* dialog modal image fix */
.css-16uayjp-MuiTypography-root-MuiDialogTitle-root{
  margin: auto !important;
  padding: 0 24px !important;

}

.css-1uvydh2 {
  padding: 9.5px 0 9.5px 14px !important;
}
/* .MuiStack-root.css-12sflcb-MuiStack-root {
  margin-top: 0 !important;
  padding-top: 0;
} */
.css-12sflcb-MuiStack-root>.MuiTextField-root, .css-12sflcb-MuiStack-root>.MuiPickersTextField-root {
  min-width: 200px;
  width: 97.5% !important;
}
.css-fhle31 > .MuiTextField-root, .css-fhle31 > .MuiPickersTextField-root {
  min-width: auto;
  width: 97.5% !important;
}
.css-1o6xmxv > .MuiTextField-root, .css-1o6xmxv > .MuiPickersTextField-root {
  min-width: auto;
  width: 99% !important;
}

/* .data-grid-container {
  display: flex;
  overflow-x: auto;
}

.pinned-column {
  display: flex;
  flex-direction: column;
  position: sticky;
  left: 0;
  background-color: white;
}

.pinned-column-header {
  background-color: #f0f0f0;
  border-bottom: 1px solid #ccc;
  padding: 8px;
}

.pinned-column-cell {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.MuiDataGrid-root {
  flex: 1;
} */

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 14px !important;
}

/* ===calender-css=== */
label[data-shrink=false]+.MuiInputBase-formControl .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px;
  margin: 0px;
}
.MuiStack-root.css-10o2lyd-MuiStack-root {
  margin: 0 !important;
  padding: 0;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  position: absolute;
  left: 0;
  top: -4px !important;}
  .MuiStack-root.css-sx5hge {
    margin: 0;
    padding: 0;
}
.MuiInputBase-input {
  padding: 10px;
}
/* ==End=calender-css=== */
.css-wf16b5 {
  background-color: rgb(7 40 93) !important;
}
.css-p0rm37{
  top: -4px !important ;
}
.css-1h6ouzl-MuiButtonBase-root-MuiTab-root{
  min-height: auto !important;
}
.css-1hbp7sl{
  min-height: auto !important;
  color: #fff !important;
}
.chart_title {
  position: absolute;
  left: 1;
  transform: translate(83%, 77%);
}
/* .tbl_container {
  width: 100%;
  height: auto;  Set a fixed height for vertical scrolling 
  overflow: auto;  Enable outer scrolling 
} */

.tbl_container {
  width: 100%;
  height: auto; /* Allow the container to expand with its content */
  overflow: visible; /* Ensure the container itself does not handle scrolling */
}

/* Optional: Ensure the table inside the container takes full width */
.tbl_innr_box {
  width: 100%;
}

.custom-header {
  display: block; /* Override the default flex display */
  white-space: nowrap; /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  overflow: hidden; /* Hide overflowed text */
  font-size: 12px;
}

.MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle {
  font-weight: 500;
  font-size: 12px;
}

.css-oo9unz-MuiDataGrid-root .MuiDataGrid-cellContent {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.tbl_box {
  width: fit-content; /* Adjust as needed */
  overflow: hidden; /* Hide inner scrolling */
}

.tbl_innr_box .data-grid {
  overflow-x: hidden !important; /* Hide inner horizontal scrollbar */
}


.chart_title_1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.user_img img {
  width: 40px;
  margin-right: 10px;
  height: 40px;
}
.user_bdr {
  /* border: 1px solid #e0e0e0; */
  padding: 0px;
  border-radius: 6px;
}
.pro-sidebar .pro-menu {
  padding-top: 15px !important;
  padding-bottom: 60px !important;
}
.pro-sidebar {
  color: #adadad;
  height: 100%;
  width:auto !important;
  min-width: auto !important;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 1009; }

.MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer button {
  color:  #dfdcdc !important;
}

/* .MuiBox-root.css-1y4ved9{
  background-image: url("/public/img/mdlbg-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;}
.MuiBox-root.css-1xz3wz4 {
  background-image: url("/public/img/mdlbg-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */

.errorpage_sec {
  position: relative;
}
.errorpage_sec button {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 15%;
}

.siderbar-drpdwn.show {
  display: contents !important;
}
/* header-css */
.header_sec {
  /* border-bottom: 1px solid #e6e6e6; */
  border-bottom: 1px solid #FBFBFB;
  /* padding-bottom: 15px; */
  background-color: #F6F1E9;
  position: fixed;
  width: 100%;
  z-index: 9999 !important;
}
.hdr-row button {
  background-color: #0e387e;
  color: #fff;
  padding: 9px 14px;
  border: none;
  border-radius: 4px;
}
.hdr-row button:hover {
  box-shadow: 0px 2px 3px -1px #000;
}
.header-logo img {
  width: 60%;
}
.nav-item {
  padding-right: 17px;
}
.link-bttm-bdr:hover {
  color: #0e387e !important;
}
.link-bttm-bdr:hover::after {
  content: "";
  width: -webkit-fill-available;
  width: -moz-available;
  height: 2px;
  border-bottom: 2px solid #ff7a00;
  position: absolute;
  bottom: -16px;
  left: 10px;
}  
.nav-link {
  position: relative;
}
.nav-linkd{
  color: #000 !important;
  display: block;
padding: .5rem 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
color: #0d6efd;
text-decoration: none;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
/* header-css-end */

/* sidebar-start */
.sidebar-sec {
  margin: 0px 0px;
  padding: 12px 0px;
  background-color: #fcfcfc;
}
.sidebar-btm-bdr {
  /* border-bottom: 1px solid #e6e6e6 !important;
  border-radius: 0 !important; */
  color: #000 !important;
  text-align: left !important;
  font-size: 15px;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
}
.sidebar-btm-bdr.active, .nav-pills .show > .nav-link {
  color: #0e387e !important;
  border-left: 3px solid #0e387e;
  background-color: inherit !important;
  border-radius: 0 !important;
  text-align: left;
}

.sidebar-innr-itm {
  color: #000 !important;
  margin-left: 20px;
text-align: left;
font-size: 15px;
}
.sidebar-innr-itm.active {
  color: #0e387e !important;
  border-left: 3px solid #0e387e;
  background-color: inherit !important;
  border-radius: 0 !important;
  font-size: 14px;
}
.nav-pills .nav-link{
  padding-right: 0;
}

.sdbr-bdr-box {
  border: 1px solid #fbfbfb;
  padding: 30px 15px;
  box-shadow: 0px 0px 5px -4px #000;
  border-radius: 4px;
  width: 20%;
  height: 100vh;
  background-color: #fff;
}
.cntnt-bdr-box {
  border: 1px solid #fbfbfb;
  padding: 30px 10px;
  box-shadow: 0px 0px 3px -2px #000;
  border-radius: 4px;
  background-color: #fff;
}
.sidebar-btm-bdr i {
  padding-right: 8px;
  text-align: left;
  font-size: 15px;
  color: #ff7a00;
  width: 30px;
  height: 30px;
  display: inline-block;
}

/* sidebar-end */

/* content-box */
.cntnt_title h3 {
font-size: 22px;
text-transform: capitalize;
font-weight: 600;
color: #0e387e;
}
.inpt-lft {
display: flex;
justify-content: end;
}
.tab_sub_cntnt p {
font-size: 15px;
color: #000;
font-weight: 400;
}
.cntnt_title {
/* border-bottom: 1px solid #e6e6e6; */
padding-bottom: 10px;
}
.tab_sub_cntnt {
padding-top: 10px;
}
.tab_sub_cntnt form {
float: inline-end;
margin: 16px 0;
}
.tab_sub_cntnt form button {
border: none;
padding: 9px 15px;
font-size: 15px;
text-transform: capitalize;
background-color: #ff7a00;
color: #fff;
border-radius: 4px;
}
.tab_sub_cntnt form button:hover{
box-shadow: 0px 2px 3px -1px #000;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-uhb5lp {
  background-image: url('https://portal.dev.ringoncore.com/static/media/mdlbg-cover.4e9a1c6f84ed8587e28d.jpg') !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.header_cps button {
  border-right: 1px solid #8b8b8b;
  border-radius: 0;
  height: 10px;
}

/* forget-password */
.forget-sec {
  /* background: url('../public/img/forget_bg_cover.jpg'); 
   background-repeat: repeat;
   background-size: auto;
 background-size: cover;
 background-repeat: no-repeat;
 padding: 66.5px 0;
 height: 100vh ; */
 /* height: auto ; */
 background: url('../public/img/forget_bg_cover.jpg');
 background-repeat: repeat;
 background-repeat: no-repeat;
 background-size: cover;
 /* background-size: cover; */
 overflow-y: hidden;
 height: 100%;
 max-height: 100vh;
 padding: 78.2px 0;
 max-height: -webkit-fill-available;
}

/* add-user */
.add_row {
padding-top: 1%;
}
.submit_button {
background-color: #0e387e;
color: #fff;
padding: 9px 14px;
border: none;
border-radius: 4px;
}
.submit_button:hover{
box-shadow: 0px 2px 3px -1px #000;
}


.login-inpt {
border: 1px solid #fff !important;
color: #fff !important;

}
.login-inpt-txt{
text-align: end;
}

.pro-sidebar .pro-menu {
  padding-top: 15px !important;
  padding-bottom: 10px; }
.login-sec {
   /* background: url('../public/img/image03.jpg'); 
    background-size: auto;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100.5px 0;
  height: 100vh !important; */
  /* height: auto !important; */
  background: url('../public/img/image03.jpg');
  background-repeat: repeat;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-size: cover; */
  overflow-y: hidden;
  height: 100%;
  min-height: 100vh;
  padding: 92px 0;
  max-height: -webkit-fill-available;
}
.login-box {
  text-align: center;
  background: transparent !important;
  /* width: 70%; */
  width: 100%;
  padding: 40px 30px;
  box-shadow: 0px 0px 63px -22px #000 !important;

  background: linear-gradient(0deg, #0b1054, rgba(255, 255, 255, 0)) !important;
}
.login-logo img{
  /* width: 75%; */
  width: 55%;
  padding-bottom: 10px;
}
.login-logo {
  /* padding-bottom: 60px; */
  /* width: 75%; */
  width: 75%;
  /* width: 100%; */
  margin: auto;
}
/* .login-inpt {
  border: 1px solid #ffffff4f;
  border-radius: 0;
  background: transparent;
} */
.login-inpt-txt a {
  font-size: 14px;
  text-align: end;
  color: #fff !important;
  text-decoration: none;
}

/* .header_sec {
  background: #00358A;
} */

.siderbar-drpdwn.show {
    display: contents !important;
  }
  section.sidebar-sec {
    /* background-color: #EAEAEA73; */
    background-color: #EEEEEE24;
    padding: 40px 0;
}
/* header-css */

.d_flex_box {
  display: flex;
  align-items: center;
  justify-content: end;
}
.filter_input.MuiInputBase-root.MuiSelect-select{
padding: 0 !important;
}
.filter_search_btn{
  padding: 10px !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  background-color: green !important;
  color: rgb(255, 255, 255) !important;
 
  margin-right: 15px !important;
}
.filter_reset_btn{
  padding: 10px !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  background-color: grey !important;
  color: rgb(255, 255, 255) !important;
 
  margin-right: 15px !important;
}



  .hdr-row button {
    background-color: #0e387e;
    color: #fff;
    padding: 9px 14px;
    border: none;
    border-radius: 4px;
  }
  .hdr-row button:hover {
    box-shadow: 0px 2px 3px -1px #000;
  }
.header-logo img {
    width: 60%;
  }

  .logo_image {
    margin-right: auto !important;
    max-width: 200px !important;
    width: 145px!important
  }



.dsbrd-tbs{
color: #092b5f;
/* background-color: inherit !important; */
/* border-bottom: 2px solid #fff;
color: #000;
border-radius: 0;
padding: 10px 0 0 !important;
font-weight: 400; */
} 

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
color: #092b5f;
/* background-color: inherit !important; */
border-bottom: 2px solid #fff;
color: #0d387f;
border-radius: 0;
padding: 10px 0 10px !important;
border: 0 !important;
border-bottom: 1px solid #08285d !important;
}
.nav-pills .nav-link.active {
font-weight: 600;
border-bottom: 1px solid #092a5f;
padding-bottom: 12px !important;
}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
isolation: isolate;
border-color:0px !important;
}
.dsbrd-tbs li button:hover {
border: 0px !important;
}
.dsbrd-tbs li:hover {
border: 0px !important;
margin: 0 !important;
}

.dsbrd-tbs li button{
color: #09295e !important;
border: 0px !important;
margin-bottom: 0px !important;
padding: 10px 0px;
}


.sip_button{
  padding: 10px !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  background-color: rgb(245, 117, 29) ;
  color: rgb(255, 255, 255) !important;
  margin-right: 15px !important;
}
.totalbutton_1{
  background-color: red !important;
}
.totalbutton_2{
  background-color: green !important;
}
.totalbutton_3{
  background-color: #09295e !important;
}







  .nav-item {
    padding-right: 35px;
  }
  .link-bttm-bdr:hover {
    color: #0e387e !important;
  }
 .link-bttm-bdr:hover::after {
    content: "";
    width: -webkit-fill-available;
    width: -moz-available;
    height: 2px;
    border-bottom: 2px solid #ff7a00;
    position: absolute;
    bottom: -16px;
    left: 10px;
  }  
  .nav-link {
    position: relative;
  }
  .nav-linkd{
    color: #000 !important;
    display: block;
  padding: .5rem 1rem;
    padding-right: 1rem;
    padding-left: 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
  }
  .dshbrd_hdr_icon ul {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0;
    padding: 0;
    list-style: none;
}
ul.hdr_profile li {
  display: flex;
  align-items: center;
}
.dshbrd_hdr_icon ul li {
  margin-right: 10px;
}
.dshbrd_hdr_icon ul li a {
  text-decoration: none;
  position: relative;
  margin-right: 5px;
}
.dshbrd_hdr_icon ul li a i {
  font-size: 20px;
  color: #f5751d;

}
ul.hdr_profile {
  /* border-left: 1px solid #f0f0f0; */
  padding-left: 20px;
  margin: 0 0px 0 20px;
}
span.notification_icon {
  font-size: 11px;
  color: #000;
  text-decoration: none;
  background-color: #fff;
  border-radius: 27px;
  width: 20px !important;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -12px;
  right: -9px;
  box-shadow: 0px 0px 11px -6px #000;
  border: 1px solid #fff;
  font-weight: 600;
}
ul.hdr_profile li img {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  list-style: none;
}
.profile_name {
  text-align: center;
}
.profile_name b {
  /* font-size: 13px;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  margin: 0;
  padding-left: 10px; */
  font-size: 14px;
  color: #000;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0;
  padding-left: 10px;

}
.profile_name p {
  margin: 0;
  font-size: 10px;
  color: #fff;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  /* color: #fff; */
  color: #092b5f;
  background-color: inherit !important;
  /* border-bottom: 2px solid #fff; */
  /* color: #000; */
  border-radius: 0;
  padding: 10px 0 10px !important;
  font-weight: 400;
}

.nav-pills .nav-link.active{
  font-weight: 600;
  padding-bottom: 10px !important;
}

li.nav-item.dsbrd-tbs button {
  font-size: 15px;
  text-transform: capitalize;
  /* color: #d9d9d9; */
  color: #092b5f;
  font-weight: 600;
  padding: 10px 0;
  font-weight: 400;
}
.tb_name {
  color: #09295e !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}
.tb_name.active {
  color: #09295e !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 28px !important;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 3px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ff7617 !important;
}
.chart-container {
  background-color: #f0f0f0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 25px;
  border-radius: 10px;
}
.pie-container {
  background-color: #f0f0f0;
  padding: 2px;
  border-radius: 10px;
}
/* content-box */
.cntnt_title h3 {
  font-size: 25px;
  text-transform: capitalize;
  font-weight: 400;
  color: #04255c;
}
.cntnt_title p {
  font-size: 0.875rem;
  color: #2d2d2d;
  margin: 0;
}
.cntnt_title {
  padding-bottom: 10px;
}
/* ==Local Start-style===manage_report-page== */
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 11.5px 14px !important
}
/* ==live Start-style===manage_report-page========= */
.css-qiwgdb {
  padding: 8.5px 14px !important
}
.css-slyssw {
  padding: 7px !important;
}
.css-1uvydh2 {
  font-size: 14px !important;
}
.css-vubbuv {
  font-size: 22px !important;
}
/* ===live End-style===manage_report-page=== */
.inpt-lft {
  display: flex;
  justify-content: end;
}
.tab_sub_cntnt p {
  font-size: 15px;
  color: #000;
  font-weight: 400;
}

.tab_sub_cntnt {
  padding-top: 10px;
}
.tab_sub_cntnt form {
  float: inline-end;
  margin: 16px 0;
}
.tab_sub_cntnt form button {
  border: none;
  padding: 9px 15px;
  font-size: 15px;
  text-transform: capitalize;
  background-color: #ff7a00;
  color: #fff;
  border-radius: 4px;
}
.tab_sub_cntnt form button:hover{
  box-shadow: 0px 2px 3px -1px #000;
}
  
  
/* add-user */
.add_row {
  padding-top: 1%;
}
.submit_button {
  background-color: #0e387e;
  color: #fff;
  padding: 9px 14px;
  border: none;
  border-radius: 4px;
}
.submit_button:hover{
  box-shadow: 0px 2px 3px -1px #000;
}




.login-inpt-txt{
  text-align: end;
}

.login-inpt::placeholder{
  color: #fff !important;
}
.login-inpt label {
  color: #fff !important;
  background-color: #211c67 !important;
}
.login-inpt:focus{
  border: 1px solid transparent !important;
}
/* .form-control.login-inpt:focus {
  color: #212529;
  background-color: #fff !important;
  border-color: #07285d !important;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgb(255, 255, 255) !important;
} */
.login-submit {
  background-color: #0e387e !important;
  color: #fff !important;
  padding: 9px 14px !important;
  border: none !important;
  border-radius: 4px !important;
  margin: 20px 0 !important;
}
.login-submit:hover {
  box-shadow: 0px 2px 3px -1px #000;
}


/* =================================== */




.info-btn.minutes-button {
  border: none;
  padding: 8px 20px;
  text-transform: capitalize;
  font-size: 16px;
  color: #fff;
  background-color: #04255c;
  font-weight: 600;
  width: 40%;
  border-radius: 5px;
}
.dashboard_tbs_one {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.vertical-bdr-line {
  border-right: 1px solid #232e3e;
}

.color_one {
  height: 8px;
  display: block;
  margin-top: 10px;
  border-radius: 13px;
}

/* =================================== */
.info-btn.minutes-button ::before {
content: "";
position: absolute;
width: 100px;
height: 100px;
background: #f6f6f6;
top: 0;
border-radius: 0px 1px 109px 0px;
left: 0;
z-index: -1;
}
.info-btn.minutes-button {
border: none;
padding: 35px 20px;
text-transform: capitalize;
font-size: 16px;
color: #04255c;
background-color: #fff;
font-weight: 600;
width: 40%;
border-radius: 5px;
box-shadow: 0px 0px 22px -9px #000;
text-align: left;
position: relative;
z-index: 1;
}
.dashboard_tbs_one {
display: flex;
justify-content: space-between;
padding: 20px 20px;
}
.dashboard_linechart {
padding: 20px 20px;
}
.vertical-bdr-line {
border-right: 1px solid #dde6f2;
}

.color_one {
height: 8px;
display: block;
margin-top: 10px;
border-radius: 13px;
}


.hdr-drpdwn {
color: white;
font-size: 12px;
border: none;
background: transparent;
box-shadow: 0px 3px 4px -1px #000;
padding: 5px 10px;
border-radius: 30px;
}
.dropbtn {
color: white;
font-size: 12px;
border: none;
background: transparent !important;
}

.hdr-drpdwn:hover, .hdr-drpdwn:focus {
/* background-color: #2980B9; */
box-shadow: 0px 3px 6px -1px #000;
}



.dropdown-content {
display: none;
position: absolute;
background-color: #f1f1f1;
min-width: 140px;
overflow: auto;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
font-size: 15px;
top: 33px;
}

.dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
margin: 0 !important;
}

.dropdown a:hover {background-color: #ddd;}

.show {display: block;}


/* ========DESHBOARD-TWO-CSS========= */
/* Fixed sidenav, full height */
.sidenav {
height: 100%;
width: 200px;
position: fixed;
z-index: 1;
top: 102px;
left: 0;
/* background-color: #012053; */
box-shadow: 0px 0px 4px -1px #000;
background: linear-gradient(180deg, #082759 0%, #001E50 100%);
overflow-x: hidden;
padding-top: 20px;
}

/* Style the sidenav links and the dropdown button */
.sidenav a, .dropdown-btn {
padding: 6px 8px 6px 16px;
text-decoration: none;
font-size: 16px;
color: #d5d5d5;
display: block;
border: none;
background: none;
width: 100%;
text-align: left;
cursor: pointer;
outline: none;
}

/* On mouse-over */
.sidenav a:hover, .dropdown-btn:hover {
color: #fff;
background: #022156;
box-shadow: 1px 1px 3px #0000003d inset;
}

/* Main content */
.main {
/* margin-left: 200px;  */
font-size: 20px; /* Increased text to enable scrolling */
/* padding: 0px 10px; */
position: relative;
top: 0px !important;
}

/* Add an active class to the active dropdown button */
.sidenav.active {
background-color: #031a42;
color: white;
}

.dropdown-container {
display: none;
background-color: #0318397d;
/* padding-left: 8px; */
}
.innr_link:hover {
color: #fff !important;
background: #022156 !important;
box-shadow: 1px 1px 3px #0000003d inset !important;
}
/* Optional: Style the caret down icon */
.fa-caret-down {
float: right;
padding-right: 8px;
}
.dashboard_innr_all_detail {
/* padding-bottom: 5%; */
display: flex;
justify-content: space-between;
}
.dashboard_innr_all_detail ul {
display: flex;
list-style: none;
padding: 0;
margin: 0;
}

.dashboard_innr_all_detail ul li button {
background: #fff;
padding: 5px 15px;
font-size: 15px;
color: #000;
border: 0px solid #ddd;
border-radius: 32px;
text-transform: capitalize;
box-shadow: 0px 0px 3px #0000003d inset;
}
.dashboard_innr_all_detail ul li button i {
font-size: 13px;
color: #0c3578;
}
/* .dashboard_innr_all_detail ul li button i:hover {
font-size: 13px;
color: #fff;
} */
.dashboard_innr_all_detail ul li button:hover i{color:#fff;}
.dashboard_innr_all_detail ul li button:hover {
border: none;
color: #fff;
padding: 5px 15px;
/* border: 1px solid #0c3477; */
font-size: 15px;
box-shadow: 0px 0px 4px -1px #000;
background: linear-gradient(180deg, #0E397F 0%, #001E50 100%) !important;
transition: 0.2s;
}

/* ===form=== */
.dashboard_form_label {
font-size: 15px;
text-transform: capitalize;
color: #000;
margin: 0;
}

.dashboard_cdr_form select {
background: transparent;
box-shadow: 0px 0px 3px #0000003d inset;
display: block;
width: 100%;
font-size: 1rem;
color: #212529;
background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
background-repeat: no-repeat;
background-position: right .75rem center;
background-size: 16px 12px;
transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.dashboard_cdr_form {
/* padding: 25px; */
padding-top: 25px;
}
.dashboard_search_caption ul {
display: flex;
list-style: none;
padding: 0;
margin: 0;
justify-content: space-between;
}
/* .dashboard_search_caption ul li button {
background: #fff;
padding: 8px 15px;
font-size: 15px;
color: #fff;
border: 1px solid #0c3477;
border-radius: 32px;
text-transform: capitalize;
box-shadow: 0px 0px 3px #0000003d inset;
background: linear-gradient(180deg, #0E397F 0%, #001E50 100%) !important;
} */
/* .dashboard_search_caption ul li button:focus{
border-color: #fff !important;
box-shadow: 0 0 0 .25rem rgb(255, 255, 255) !important;
color: #fff !important;
}
.dashboard_search_caption ul li button:hover {
border: none;
color: #fff;
padding: 8px 15px;
border: 1px solid #0c3477;
font-size: 15px;
box-shadow: 0px 0px 4px -1px #000;
background: linear-gradient(180deg, #0E397F 0%, #001E50 100%) !important;
transition: 0.2s;
} */
.dashboard_search_caption ul li p {
font-size: 14px;
text-transform: capitalize;
color: #000;
}
.dashboard_search_caption input:focus {
border: 1px solid transparent;
display: block !important;
box-shadow: 0 0 0 .25rem rgb(246, 246, 246) !important;
}
.innr_search span {
border-radius: 0px 26px 26px 0px;
border: 0;
font-size: 15px;
cursor: pointer;
}
.innr_search input {
border-radius: 26px 0px 0px 26px !important;
}
.innr_search input:focus {
border-radius: 26px 0px 0px 26px !important;
border: 0;
}
.dashboard_search_caption {
padding-top: 1%;
padding: 0 25px 25px;
}
.fnt_bld{
font-size: 20px !important;
}

/* ======table-section===== */
/* .table_row {
padding-top: 5%;
} */
.table_row, table, thead, tr, th {
font-size: 15px;
font-weight: 700 !important;
text-transform: capitalize;
}
.table_row, table, thead, tr, th, td {
font-size: 15px;
font-weight: 400;
}
.dashboard_pagination {
display: flex;
justify-content: center;
padding-top: 35px;
}
.dashboard_pagination nav .pagination {
margin: 0;
}
.pgntn_btn_left a {
border-radius: 25px 0px 0px 25px !important;
border: 0;
background-color: #0c367a !important;
color: #fff !important;
font-size: 15px;
}
.pgntn_btn_right a {
border-radius: 0px 25px 25px 0px !important;
border: 0;
background-color: #0c367a !important;
color: #fff !important;
font-size: 15px;
}
.page-item:not(:first-child) .page-link {
margin-left: -1px;
font-size: 14px;
border: 0;
color: #000;
}

.page-item.active .page-link{
background-color: #0c367a !important;
color: #fff;
}
.page-link:focus{
background-color: #fff !important;
color: #fff;
border: 0;
box-shadow: 0 0 0 .25rem rgb(194, 109, 109) !important;
}

/* =====DACHBOARD-TWO-CSS-END================= */

.custom-header {
  font-weight: bold !important;
  color: #fff  ;
  font-size: 15px;
  background-color: #0c367a ;
  border: 0px;
  }
  .MuiDataGrid-columnHeaderTitle.css-t89xny-MuiDataGrid-columnHeaderTitle {
    font-weight: 600;
  }

  .css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
    cursor: pointer;
    color: inherit;
    text-align: center;
    font-size: 1.5rem;
    color: #fff !important;
    font-size: 1.125rem;
    
  }

  .MuiDataGrid-columnHeaderTitle.css-mh3zap {
    font-weight: 600 !important;
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeSmall.css-1j7qk7u {
    color: #fff !important;
  }

  .MuiBox-root.css-s1kcrx{
    background-image: url("/public/img/mdlbg-cover.jpg") !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  

.all_button_clr{
  padding: 10px !important;
  font-size: 15px !important;
  border-radius: 5px !important;
  background-color: rgb(245, 117, 29) !important;
  color: rgb(255, 255, 255) !important;
  margin-left: auto ;
  margin-right: 15px  !important;
}
.all_button_clr:hover{
  color: #fff;
  font-size: 15px;
  box-shadow: 0px 0px 4px -1px #000;
  background: linear-gradient(180deg, #0E397F 0%, #001E50 100%) !important;
  transition: 0.2s;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
}
.bg_imagess {
  background-image: url("/public/img/mdlbg-cover.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  background-image: url("/public/img/mdlbg-cover.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* ======header-two====== */
.manage_top_header{
  box-shadow: rgb(206, 206, 206) 0px 0px 4px -1px !important;
  /* background-color: rgba(253, 253, 253, 0.97) !important; */
  background-color: #fff !important;
}
.manage_tggle {
  margin: 0 !important;
  color: #f5751D !important;
  margin-left: 20px !important;
}
.manage_user {
  color: #f5751D !important;
  margin-right: 20px !important;
}
.manage_top_header img {
  /* border-right: 1px solid #f4f4f4;
  padding-right: 20px; */
  /* width: 80%; */
}
.admin_logo{
  width: 145px !important;
}
.call_icon{
  color: #f5751D !important;
}
.manage_call {
  font-size: 15px !important;
  color: #000 !important;
  cursor: inherit !important;
}
.css-mf1cb5-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: transparent !important;
}
.rounded-circle {
  border-radius: 50%; /* Creates a circular shape */
  width: 50px; /* Adjust the width and height as needed */
  height: 50px;
  object-fit: cover; /* Ensures the image covers the entire container */
}
/* .body_row{
  background-color: #141b2b;
} */
main.right_sidebox {
  position: relative;
  /* top: 65px; */
  padding-top: 6em;
  /* height: 100vh; */
  /* height: 100%; */
  left: 15rem;
  /* z-index: 999; */
  max-height: 100%;
  min-height: 100vh;
}
.tab_cntnt_box {
  padding-top: 100px !important;
}
.manage_boxx {
  position: fixed;
  z-index: 999 !important;
  width: 100% !important;
}
.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
  box-sizing: border-box;
  width: 240px;
  top: 65px;
}
sub.live_icon {
  position: relative;
  bottom: 14px;
  right: 7px;
  background-color: #f6f1e9;
  padding: 4px 10px;
  border-radius: 22px;
  color: #07285d;
}
.mdl_show {
  position: relative;
  z-index: 99999 !important;
}
div#app {
  position: fixed;
  top: 4rem !important;
}

.manage_rgiht_bdr button:hover {
  background-color: transparent !important;
}
.css-1q2h7u5{
  color: #fff !important;
 }
.css-1q2h7u5.Mui-selected {
    color: rgb(255 255 255) !important;
}
.css-ttwr4n {
  position: absolute;
  height: 2px;
  bottom: 0px;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: rgb(245 117 29) !important;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #fff !important;
}
.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 3px;
  bottom: 0;
  width: 100%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ff7617 !important;
}
/* ====media quiery=== */

.destination_modal.MuiBox-root.css-1xz3wz4 {
  background-image: url("/public/img/mdlbg-cover.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
/* .MuiPaper-root.MuiPaper-elevation {
  background-image: url("/public/img/mdlbg-cover.jpg") !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
} */
/* =====scrollbar-css===== */

  /* Firefox */
  * {
    /* scrollbar-width: thin; */
    scrollbar-width: auto;
    /* scrollbar-color: #07285d #DFE9EB; */
    scrollbar-color: #07285d #e0e0e0;
  }
  
  /* Chrome, Edge and Safari */
  *::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  *::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
  }
  
  *::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
  }
  
  *::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
  }
  
  *::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #07285d;
  }
  
  *::-webkit-scrollbar-thumb:hover {
    background-color: #62A34B;
  }
  
  *::-webkit-scrollbar-thumb:active {
    background-color: #62A34B;
  }

@media (max-width: 810.98px) { 
  .MuiTabs-root.nav_bar_horizontal.MuiTabs-scroller.MuiTabs-flexContainer {
    overflow: scroll !important;
    width: 100% !important;
  }
  .MuiTabs-flexContainer.css-heg063-MuiTabs-flexContainer {
    overflow: scroll !important;
  }
 
}

@media (max-width: 575.98px) { 
  .css-1x5jdmq {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 2.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 4.5px 14px !important;
    justify-content: space-between;
}
  .cntnt_title h3 {
    color: #04255c;
    font-size: 18px;
    font-weight: 600;
    text-transform: capitalize;
}

  ul.hdr_profile li img {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    list-style: none;
  }
  .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
    background: #07285d !important;
  }
  .logo_image {
    width: 80%;
}
ul.hdr_profile{
  padding-left: 0;
}
.all_btn_pdng {
  margin-bottom: 10px !important;
}
.all_button_clr {
  padding: 5px !important;
  font-size: 13px !important;
  border-radius: 5px !important;
  background-color: rgb(245, 117, 29) !important;
  color: rgb(255, 255, 255) !important;
  margin-left: auto !important;
  margin-right: 4px !important;
}
.MuiTabs-scroller.MuiTabs-fixed.css-1anid1y {
  overflow: auto !important;
}
.mobile_view{
  width: 100% !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 0 !important;
  padding: 0 !important;
  width: 92%;
}
.mobile_width{
  width: 92% !important;
}
.cntnt_title p {
  font-size: 0.875rem;
  color: #2d2d2d;
  margin: 0;
  width: 94%;
}
main.right_sidebox{
  position: relative;
  left: 0;;
}
div#app {
  position: absolute;
  top: 4rem !important;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #07285d !important;
  height: 100%;
  position: relative;
  z-index: 101;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  background: #07285d !important;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
  background-color: transparent !important;
}
.cntnt_title {
  padding-top: 0px;
}
.menu_icon_button {
  background-color: #0c367a !important;
  color: #fff !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  padding: 4px !important;
  top: 162px !important;
  right: 10px;
  position: fixed !important;
  
}
.menu_icon_button_box:hover {
  background-color: #fff !important;
  color: #fff !important;
  font-size: 14px !important;
  border-radius: 5px !important;
  
}
.dropbtn {
  padding: 0 !important;
}
ul.hdr_profile {
  margin: 0 0px 0 0px !important;
}
.dshbrd_hdr_icon ul li {
  margin-right: 0px;
}
.manage_rgiht_bdr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.tab_cntnt_box {
  padding-top: 130px !important;
}
.manage_mobile_logo {
  padding: 8px;
  background-color: #fff;
}
.mobile_logo_center{
  display: flex;
    justify-content: center;
}
.mobile_top_pddng {
  /* padding-top: 13em !important; */
  padding: 13em 8px !important;
}
.manage_top_header {
  position: relative !important;
  z-index: 999999 !important;
}
.d_flex_box {
  display: grid;
  align-items: center;
  justify-content: center;
}
/* ===filter css==== */
.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  cursor: pointer;
  color: inherit;
  text-align: center;
  font-size: 1.5rem;
  color: #d93e3e !important;
  font-size: 1.125rem;
  display: none !important;
}
.css-1t5wrdm-MuiDataGrid-filterForm {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  flex-direction: column !important;
}
.css-154sxbz-MuiPaper-root-MuiDataGrid-paper {
  min-width: auto !important;
}
main.right_sidebox {
 
  /* min-height: auto !important; */
  padding: 0;
}
.App.theme_white .contant_box {
  margin: 0 auto;
  background-color: #141B2B;
  color: #f1f1f1;
  height: auto;
}
.MuiStack-root.css-10o2lyd-MuiStack-root {
  padding: 0;
  margin: 5px 0 !important;
}
.billing_minute{
  display: none;
}

.billing_minute_mobile{
  display: block;
}
.css-mhc70k-MuiGrid-root{
  display: inline-block !important;
  width: 100% !important;
  display: inherit;
}

 }


@media (max-width: 767.98px) {
  .pro-sidebar .pro-menu {
    padding-top: 40px !important;
}
  .css-mhc70k-MuiGrid-root{
    display: inline-block !important;
    width: 100% !important;
    display: inherit;
  }
  .bg_imagess {
    border: 1px solid #fff !important;
    border-radius: 10px !important;
    text-align: center !important;
    width: 90% !important;
}
.all_btn_pdng {
  margin-bottom: 10px !important;
}
.mrgn_top{
  margin-top: 0px !important;
}
.pie-container {
  margin: 34px 0 0;
}
.form_flied_box {
  width: 94% !important;
}
.dashboard_search_caption{
  padding: 0;
}
.dashboard_search_caption ul {
  display: inherit;
}
ul.reset_section {
  display: flex;
  padding-top: 15px;
}
.dashboard_search_caption ul li small {
  font-size: 15px !important;
}
.dashboard_innr_all_detail{
  display: inherit;
}

.extension_title {
  width: 100% !important;
}
.MuiTabs-scroller.MuiTabs-fixed.css-1anid1y {
  overflow: auto !important;
}
.mobile_view{
  width: 100% !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  margin: 0 !important;
  padding: 0 !important;
  width: 92%;
}
.mobile_width{
  width: 92% !important;
}
.MuiStack-root.css-10o2lyd-MuiStack-root {
  padding: 0;
  margin: 5px 0 !important;
}
 }


 .pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
  position: relative;
  background-color: #0e387e !important;
}


 /* ======table_header_bg_color===== */
 .MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-1iyq7zh-MuiDataGrid-columnHeaders {
  background: #0c367a !important;
}
.MuiDataGrid-columnHeaders.MuiDataGrid-withBorderColor.css-y599qu {
  background: #0c367a;
  display: block;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:focus {
  outline: none;
  color: #f5751d !important;
}
.pro-menu-item:hover {
  /* background: #f5751d !important; */
  color: #fff;
  box-shadow: inset 0 0 7px 5px #f5751d !important;
}
.pro-menu-item:focus {
  background: #f5751d !important;
  color: #fff;
}
.pro-menu-item.active {
  background: #f5751d !important;
  color: #fff;
}
.pro-menu-item:hover .pro-inner-item span {
  color: #fff !important;
}

/* ======start-User_profile_css====== */
.profile_image {
  width: 170px;
  height: 18 0px;
  border-radius: 50%;
}
.card_box {
  text-align: center;
}
.card_button_box {
  display: flex;
  justify-content: center;
  padding-bottom:20px !important;
}
.form_profile_box {
  padding: 15px 0;
}
.profile_form_input {
  margin: 6px 15px !important;
  width: 96%;
}
.profile_form_input label {
  font-size: 15px;
  padding-left: 0 !important;
  color: #000;
  font-weight: 400;
}
.all_button_clr{
  margin-bottom: 0 !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
  font: inherit !important;
  letter-spacing: inherit !important;
  color: currentColor !important;
  padding: 4px 0 5px 16px !important;
  border: 0 !important;
  box-sizing: content-box !important;
  background: none !important;
  height: 1.4375em !important;
  margin: 0 !important;
  -webkit-tap-highlight-color: transparent !important;
  display: block !important;
  min-width: 0 !important;
  width: 100% !important;
  -webkit-animation-name: mui-auto-fill-cancel !important;
  animation-name: mui-auto-fill-cancel !important;
  -webkit-animation-duration: 10ms !important;
  animation-duration: 10ms !important;
}

.MuiFormControl-root.profile_form_input {
  padding-left: 15px !important;
  padding-top: 20px;
}


main.right_sidebox.user_profile_page {
  background-color: #f2f2f2 !important;
}

/* ======start-User_profile_css====== */

.pie-container h4 {
  text-align: center;
  padding: 20px 0 0;
  color: #000;
  font-size: 20px;
}

.input_fields {
  width: 100% ;
  /* border-bottom: 1px solid #ccc !important; */
  margin-bottom: 25px !important;
}
.input_fields div .MuiInputBase-input .MuiOutlinedInput-input .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
  padding-bottom: 0px !important;
  height: 2.5375em !important;
  padding: 2.5px 14px 0px !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 2.4375em !important;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0;
  width: 100%;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding: 3.5px 14px !important;
}
.input_fields .MuiInputBase-root input{
  padding-bottom: 0px !important;
}
.input_fields div .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  /* border: 0 !important; */
  padding-bottom: 0px !important;
}

fieldset {
  /* border: 0 !important; */
}

/* ===start=permission=== */
.select_title {
  background-color: #ccc;
  width: 100%;
  text-align: center;
  margin: 0 !important;
}
.select_title span {
  font-size: 16px;
  color: #000;
  font-weight: 600;
}
.selectgroup_box {
  border-right: 1px solid #ccc;
  /* border: 1px solid #ccc; */
  padding-left: 0;
}
.selectgroup_row {
  padding: 0 10px;
}
/* ===end=permission=== */



@media (min-width: 569px) and (max-width: 769.9px) { 
  .side_bar_media{
    display: block;
  }
    .menu_icon_button_box{
      display: none !important;
    }
    
    .main.right_sidebox {
      /* min-height: auto !important; */
  }
  
  
 }
 @media (min-width: 769.9px) and (max-width: 1199.98px) { 

  .menu_icon_button_box{
    display: none !important;
  }
  .css-1x5jdmq {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 2.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 4.5px 14px !important;
    justify-content: space-between !important;
}
  /* main.right_sidebox {
    position: absolute;
    /* top: 65px; */
   
    /* height: 100vh; 
    left: 15rem;
    background: #1a2336;
    /* z-index: 999; */
    /* min-height: 100vh; 
} */
 }

 @media (max-width: 991.98px) {

  .css-1x5jdmq {
    font: inherit;
    letter-spacing: inherit;
    color: currentcolor;
    border: 0px;
    box-sizing: content-box;
    background: none;
    height: 2.4375em;
    margin: 0px;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 100%;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    padding: 4.5px 14px !important;
    justify-content: space-between !important;
}
.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
  display: flex;
  justify-content: space-between;
}
  }